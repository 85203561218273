import React from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage } from '../utils/utils';
import ResetGameButton from '../components/ResetGameButton';

function CharacterBubblePage(props) {
  return (
    <div id='character_bubble_page' className='page_container'>
      <ResetGameButton />
      <div className='text_bubble large_character_text_bubble'>
        <h1>{props.title}</h1>
          <p dangerouslySetInnerHTML={{__html: props.text}}></p>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => { moveToNextChallengePage() }}
            sx={{
              mt: 3,
              mb: 2,
              fontSize: '1.5rem',
            }}
          >
            Continue
          </Button>
      </div>
      <div className='large_character_bubble_tail'>
      </div>
      <div className='large_character_container'>
        <div className='large_circle_image_frame'>
          <img src={`/images/${props.bubbleImage}`} alt='Industry icon'></img>
        </div>
        <div className='large_character_image_frame'>
        <img src={`/images/${props.characterImage}`} alt='Large character'></img>
        </div>
      </div>
    </div>
  );
}

export default CharacterBubblePage;
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff9900', // AWS Orange color for primary buttons
    },
    // ... (Other colors)
  },

  // You can also define overrides for the Button component if needed
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // If you want to make sure all buttons are styled as contained by default you can do:
          // variant: 'contained',
        },
        containedPrimary: {
          backgroundColor: '#8056D6', // AWS Orange
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#6942b7', // A slightly darker orange on hover
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        // Name of the slot
        colorDefault: {
          backgroundColor: '#0f1b2a', // AWS Background
          color: '#fff', // AWS Text Color
        },
      },
    },
  },
  // ... (Other overrides and theme options)
});


export default theme;
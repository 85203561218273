import React from "react";

export default function SmallCharacterBubble(props) {
  return (
    <div className='small_character_container'>
      <div className='small_circle_image_frame'>
        <img src={`/images/${props.characterImage}`} alt='Character avatar'></img>
      </div>
      <div className='small_character_text_bubble'>
      <span dangerouslySetInnerHTML={{__html: props.characterSpeech}}></span>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage, getPromptInjectionErrorScore, setPromptInjectionErrorScore } from '../../utils/utils';
import SmallCharacterBubble from '../../components/SmallCharacterBubble';
import PromptContainer from '../../components/PromptContainer';
import ResultModal from '../../components/ResultModal';
import ResetGameButton from '../../components/ResetGameButton';

function PromptInjectionPage(props) {
  const [instructionOptions, setInstructionOptions] = useState([]);
  const [contextOptions, setContextOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  // add selected state and id to options
  useEffect(() => {
    let newInstructionOptions = [];
    let newContextOptions = [];
    let newTaskOptions = [];
    Object.keys(props.options).forEach((key) => {
      props.options[key].forEach((option, index) => {
        const optionObj = {
          ...option,
          selected: false,
        }
        if (key === 'instruction') {
          optionObj.type = 'instruction';
          newInstructionOptions.push(optionObj);
        } else if (key === 'context') {
          optionObj.type = 'context';
          newContextOptions.push(optionObj);
        } else if (key === 'task') {
          optionObj.type = 'task';
          newTaskOptions.push(optionObj);
        }
      });
    })
    setInstructionOptions(newInstructionOptions);
    setContextOptions(newContextOptions);
    setTaskOptions(newTaskOptions);
  }, [props.options]);

  function clearSelectedOptions(currentOptions, setter) {
    let newOptions = [...currentOptions];
    // reset all options to unselected
    newOptions.forEach((option) => { option.selected = false });
    setter(newOptions);
  }

  function setSelectedOption(currentOptions, index, setter) {
    let newOptions = [...currentOptions];
    newOptions[index].selected = true;
    setter(newOptions);
  }


  function setCurrentlySelectedOption(option, index) {
    clearSelectedOptions(instructionOptions, setInstructionOptions);
    clearSelectedOptions(contextOptions, setContextOptions);
    clearSelectedOptions(taskOptions, setTaskOptions);
    if (option.type === 'instruction') {
      setSelectedOption(instructionOptions, index, setInstructionOptions);
    } else if (option.type === 'context') {
      setSelectedOption(contextOptions, index, setContextOptions);
    } else if (option.type === 'task') {
      setSelectedOption(taskOptions, index, setTaskOptions);
    }
  }


  function validateAndScoreAnswer(option) {
    let currentScore = getPromptInjectionErrorScore();
    if (currentScore === null) {
      currentScore = props.startingPoints;
    }

    if (!option.correct) {
      setModalSuccess(false);
      currentScore = currentScore - props.pointPenalty;
    } else {
      setModalSuccess(true);
    }
    setPromptInjectionErrorScore(currentScore);
    setDisplayModal(true);
  }


  function handleOptionClick(option, index) {
    setCurrentlySelectedOption(option, index);
    setCurrentOption(option);
  }

  function handleSubmit() {
    validateAndScoreAnswer(currentOption);
  }

  function handleModalClose() {
    setDisplayModal(false);
  }

  function OptionsList(props) {
    return (
      <ul className='prompt_choice_list'>
        {
          props.options.map((option, index) => {
            return (
              <li key={index}
                onClick={() => handleOptionClick(option, index)}
                className={option.selected ? 'selected_green' : ''}
                // Add slight gap between options within the same group
                style={index != 0 && props.options.length !== 1 ? { marginTop: '10px' } : { marginTop: '0' }}
              >
                {option.text}
              </li>
            )
          })
        }
      </ul>
    );
  }

  return (
    <div id='promt_result_page' className='page_container'>
      <ResetGameButton />
      <ResultModal
        display={displayModal}
        message={modalSuccess ? props.correctMessage : props.retryMessage}
        onClick={() => { modalSuccess ? moveToNextChallengePage() : handleModalClose() }}
      />

      <SmallCharacterBubble
        characterImage={props.characterImage}
        characterSpeech={props.characterSpeech}
      />
      <PromptContainer label='Incorrect Output' mode='failed'>
        {props.incorrectOutput}
      </PromptContainer>

      <h3>Select the incorrect prompt section:</h3>
      <PromptContainer label='Prompt'>
        <div className="category_container">
          <div className="category_label">
            Instruction
          </div>
          <OptionsList options={instructionOptions} />
        </div>
        <div className="category_container">
          <div className="category_label">
            Context
          </div>
          <OptionsList options={contextOptions} />
        </div>
        <div className="category_container">
          <div className="category_label">
            Task
          </div>
          <OptionsList options={taskOptions} />
        </div>

      </PromptContainer>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        disabled={currentOption === null ? true : false}
        onClick={() => { handleSubmit() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.5rem',
        }}
      >
        Select Option
      </Button>
    </div>
  );
}

export default PromptInjectionPage;
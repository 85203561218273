import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage, getGameMetadata, getDeniedTopicsScore, getPiiProtectionScore, setLastChallengeResponse } from '../../utils/utils';
import SmallCharacterBubble from '../../components/SmallCharacterBubble';
import PromptContainer from '../../components/PromptContainer';
import ResetGameButton from '../../components/ResetGameButton';

function Challenge3ResultPage(props) {
  useEffect(() => {
    let ignore = false;

    async function processStoredAnswers() {
      const gameMetadata = getGameMetadata();
      console.log('help')
      const score = getDeniedTopicsScore() + getPiiProtectionScore();

      // dev endpoint
      const url = '/api/core-gameplay/submit-challenge-response';
      const response = await fetch(url,
        {
          method: 'POST',
          body: JSON.stringify({
            'userID': gameMetadata.userID,
            'gamePlayID': gameMetadata.gamePlay.gamePlayID,
            'challengeID': gameMetadata.game.gameConfig.gameChallengeIDs[2],
            'userResponse': {},
            'score': score
          })
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (!ignore) {
          console.log('Challenge input response: ', data);
          setLastChallengeResponse(data);
        }
      } else {
        console.log("Error with game start response", response);
      }
    }

    if (props.sendScore) {
      processStoredAnswers()
    };

    return () => {
      ignore = true;
    };

  }, [props.sendScore]);


  return (
    <div id='promt_result_page' className='page_container'>
      <ResetGameButton />
      <SmallCharacterBubble
        characterImage={props.characterImage}
        characterSpeech={props.characterSpeech}
      />
      <PromptContainer label='Prompt'>
        {props.prompt}
      </PromptContainer>
      <PromptContainer label='Model-Only Output' mode='failed'>
        <span dangerouslySetInnerHTML={{ __html: props.response.model }}></span>
      </PromptContainer>
      <PromptContainer label='Bedrock Guardrails Enabled' mode='success'>
        <span dangerouslySetInnerHTML={{ __html: props.response.guardrails }}></span>
      </PromptContainer>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={() => { moveToNextChallengePage() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.5rem',
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default Challenge3ResultPage;
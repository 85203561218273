import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

function WelcomePage() {
  const navigate = useNavigate();

  return (
    <div id='title_page' className='page_container'>
      <h1>Safety Guardrails with Amazon Bedrock</h1>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => { navigate('/game/select-scenario'); }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '60px',
        }}
      >
        Touch to start
      </Button>
      <div id='hero_image'>
        <img src='images/hero_image.jpg' alt='Title'></img>
      </div>
    </div>
  );
}

export default WelcomePage;
import React from "react";

export default function ResetGameButton(props) {
  return (
    <div 
      className='reset_button'
      onClick={() => { window.location.href = '/'; }}
    >
      ⤬
    </div>
  );
}
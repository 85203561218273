import React from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage } from '../utils/utils';
import ResetGameButton from '../components/ResetGameButton';

function DetailedExplanationPage(props) {
  return (
    <div id='detailed_explanation_page' className='page_container'>
      <ResetGameButton />
      <h1>{props.title}</h1>
      <div className='large_image_frame'>
        <img src={`/images/${props.image}`} alt={props.image}></img>
      </div>
      <h2>{props.subtitle}</h2>
      <p dangerouslySetInnerHTML={{__html: props.text}}></p>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={() => { moveToNextChallengePage() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.5rem',
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default DetailedExplanationPage;
import React from "react";

export default function PromptContainer(props) {
  function getLabelStyle() {
    let className = '';
    if(props.mode === 'success') {
      className = 'prompt_label_success';
    } else if (props.mode === 'failed') {
      className = 'prompt_label_failed';
    }
    return 'prompt_label ' + className;
  }

  function getContainerStyle() {
    let className = '';
    if(props.mode === 'success') {
      className = 'prompt_container_success';
    } else if (props.mode === 'failed') {
      className = 'prompt_container_failed';
    }
    return 'prompt_container ' + className;
  }

  return (
    <div className={getContainerStyle()}>
      <div className={getLabelStyle()}>{props.label}</div>
      {props.children}
    </div>
  );
}
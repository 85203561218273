import React, { useEffect } from 'react';

export default function AppstreamRedirect(props) {
  useEffect(() => {
    window.location.replace("/");
  }, []);

  return (
    <div>
    </div>
  );
}
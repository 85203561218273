import React, { useState, useEffect } from 'react';
import CharacterBubblePage from './CharacterBubblePage';
import DetailedExplanationPage from './DetailedExplanationPage';
import MultipleChoicePage from './Challenge1/MultipleChoicePage';
import PromptResultPage from './Challenge1/PromptResultPage';
import PromptInjectionPage from './Challenge2/PromptInjectionPage';
import ExtendedMultipleChoicePage from './Challenge2/ExtendedMultipleChoicePage';
import Challenge2ResultPage from './Challenge2/Challenge2ResultPage';
import Challenge3McPage from './Challenge3/Challenge3McPage';
import Challenge3ResultPage from './Challenge3/Challenge3ResultPage';
import ChallengeEndPage from './ChallengeEndPage';
import { getGameSequence, getGameStage } from '../utils/utils';

function ChallengeManager() {
  const [currentChallengePage, setCurrentChallengePage] = useState({});

  useEffect(() => {
    try {
      let sequence = getGameSequence();
      let stage = getGameStage();
      setCurrentChallengePage(sequence[stage.challenge][stage.page]);
    } catch (e) {
      console.log('Error loading game state, did you choose a game scenario and correctly start a game?');
      console.error(e);
    }
  }, []);

  function getCurrentChallengePage(pageConfig) {
    switch(pageConfig.type) {
      // Common pages
      case 'CharacterBubblePage':
        return <CharacterBubblePage
          title={pageConfig.title}
          characterImage={pageConfig.characterImage}
          bubbleImage={pageConfig.bubbleImage}
          text={pageConfig.text}
        />
      case 'DetailedExplanationPage':
        return <DetailedExplanationPage
          title={pageConfig.title}
          subtitle={pageConfig.subtitle}
          image={pageConfig.image}
          text={pageConfig.text}
        />
      case 'ChallengeEndPage':
        return <ChallengeEndPage
          title={pageConfig.title}
          characterImage={pageConfig.characterImage}
          bubbleImage={pageConfig.bubbleImage}
          text={pageConfig.text}
          points={pageConfig.points}
        />
        
      // Challenge 1 specific
      case 'MultipleChoicePage':
        return <MultipleChoicePage
          choiceType={pageConfig.choiceType}
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          options={pageConfig.options}  
        />
      case 'PromptResultPage':
        return <PromptResultPage
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
        />
      
      // Challenge 2 specific
      case 'PromptInjectionPage':
        return <PromptInjectionPage
          choiceType={pageConfig.choiceType}
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          incorrectOutput={pageConfig.incorrectOutput}
          startingPoints={pageConfig.startingPoints}
          pointPenalty={pageConfig.pointPenalty}
          retryMessage={pageConfig.retryMessage}
          correctMessage={pageConfig.correctMessage}
          options={pageConfig.options}  
        />
      case 'ExtendedMultipleChoicePage':
        return <ExtendedMultipleChoicePage
          choiceType={pageConfig.choiceType}
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          options={pageConfig.options}
          startingPoints={pageConfig.startingPoints}
          pointPenalty={pageConfig.pointPenalty}
          retryMessage={pageConfig.retryMessage}
          correctMessage={pageConfig.correctMessage}
        />
      case 'Challenge2ResultPage':
        return <Challenge2ResultPage
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          input={pageConfig.input}
        />

      // Challenge 3 specific
      case 'Challenge3McPage':
        return <Challenge3McPage
          choiceType={pageConfig.choiceType}
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          options={pageConfig.options}
          startingPoints={pageConfig.startingPoints}
          pointPenalty={pageConfig.pointPenalty}
          retryMessage={pageConfig.retryMessage}
          correctMessage={pageConfig.correctMessage}
          prompt={pageConfig.prompt}
        />
      case 'Challenge3ResultPage':
        return <Challenge3ResultPage
          characterImage={pageConfig.characterImage}
          characterSpeech={pageConfig.characterSpeech}
          prompt={pageConfig.prompt}
          response={pageConfig.response}
          sendScore={pageConfig.sendScore}
        />

      default:
       return 
    }
  }

  return getCurrentChallengePage(currentChallengePage);
}

export default ChallengeManager;
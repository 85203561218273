import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { moveToNextChallengePage, getMultipleChoiceAnswers, setMultipleChoiceAnswer } from '../../utils/utils';
import SmallCharacterBubble from '../../components/SmallCharacterBubble';
import PromptContainer from '../../components/PromptContainer';
import ResetGameButton from '../../components/ResetGameButton';

function MultipleChoicePage(props) {
  const [options, setOptions] = useState([]);

  // add selected state to options
  useEffect(() => {
    let transformedOptions = [];
    props.options.forEach((option, index) => {
      const optionObj = {
        ...option,
        selected: false,
      }
      transformedOptions.push(optionObj);
    });
    setOptions(transformedOptions);
  }, [props.options]);

  function handleOptionClick(index) {
    let newOptions = [...options];
    // reset all options to unselected, then only select current one
    newOptions.forEach((option) => { option.selected = false });
    newOptions[index].selected = true;
    setOptions(newOptions);
    setMultipleChoiceAnswer(props.choiceType, newOptions[index]);
  }

  function OptionsList() {
    return (
      <ul className='multiple_choice_list'>
        {
          options.map((option, index) => {
            return (
              <li key={index}
                onClick={() => handleOptionClick(index)}
                className={option.selected ? 'selected_green' : ''}
              >
                {option.text}
              </li>
            )
          })
        }
      </ul>
    )
  }

  function getPromptSectionStyle(section) {
    return props.choiceType === section ? 'prompt_current_selection' : 'prompt_pending_selection';
  }

  function DisplayInput() {
    const answers = getMultipleChoiceAnswers();
    const instructionText = answers.hasOwnProperty('instruction') ? answers.instruction.text : 'Instruction to be filled';
    const contextText = answers.hasOwnProperty('context') ? answers.context.text : 'Context to be filled';
    const taskText = answers.hasOwnProperty('task') ? answers.task.text : 'Task to be filled';

    return (
      <div>
        Human:
        <div className={'prompt_instruction ' + getPromptSectionStyle('instruction')}>
          {instructionText}
        </div>
        &lt;user-info&gt;
        <div className={'prompt_context ' + getPromptSectionStyle('context')}>
          {contextText}
        </div>
        &lt;/user-info&gt;
        <div className={'prompt_task ' + getPromptSectionStyle('task')}>
          {taskText}
        </div>
        Assistant:
      </div>
    );
  }


  return (
    <div id='multiple_choice_page' className='page_container'>
      <ResetGameButton />
      <SmallCharacterBubble
        characterImage={props.characterImage}
        characterSpeech={props.characterSpeech}
      />

      <PromptContainer label='Prompt'>
        <DisplayInput />
      </PromptContainer>

      <h3>Select One:</h3>
      <OptionsList />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        disabled={options.filter((option) => option.selected).length === 0}
        onClick={() => { moveToNextChallengePage() }}
        sx={{
          mt: 3,
          mb: 2,
          fontSize: '1.6rem',
        }}
      >
        Select Option
      </Button>
    </div>
  );
}

export default MultipleChoicePage;
import React from "react";
import { Button } from '@mui/material';

export default function ResultModal(props) {
  return (
    <div className='result_modal' style={{ display: props.display ? "flex" : "none"}}>
      <div className='modal_panel'>
        <h1>{props.message.title}</h1>
        <p dangerouslySetInnerHTML={{__html: props.message.message}}></p>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={() => { props.onClick() }}
          sx={{
            mt: 3,
            mb: 2,
            fontSize: '1.5rem',
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}